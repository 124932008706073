<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">微信分账用户列表</div>
      <el-form :inline="true" size="mini"> </el-form>
      <!-- <div class="c-title">数据列表</div> -->
      <el-table :data="dataList" size="mini" :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">

        <el-table-column label="编号" prop="id" width="50px"> </el-table-column>
        <el-table-column v-if="this.sa_admin.role == 2" label="所属码队" prop="codeMchName" width="100px"></el-table-column>
        <el-table-column label="分账用户姓名" prop="shareName" width="100px" />
        <el-table-column label="分账用户openid" prop="shareOpenId" width="230px"></el-table-column>
        <el-table-column label="微信商户号" prop="wechatMch" width="100px"></el-table-column>
        <el-table-column label="创建时间" prop="createdTime" width="200px"></el-table-column>
        <el-table-column label="模式" width="100px">
          <template slot-scope="s">
            <b v-if="s.row.mode == 0">单商户模式</b>
            <b v-if="s.row.mode == 1">服务商模式</b>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="s">
            <el-button class="c-btn" type="danger" icon="el-icon-delete" @click="del(s.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="page-box">
        <el-pagination background layout="total, prev, pager, next, sizes, jumper" :current-page.sync="p.page" :page-size.sync="p.limit" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      p: {
        page: 1,
        limit: 10,
      },
      dataCount: 1422,
      dataList: [],
    };
  },
  methods: {
    // 数据刷新
    f5: function () {
      var defaultCfg = {
        type: "get", // 默认请求类型
      };
      this.sa.ajax(
        "/v1/profitshare_account/list",
        this.p,
        function (res) {
          this.dataList = res.data.records; // 数据
          this.dataCount = res.data.total; // 分页
        }.bind(this),
        defaultCfg
      );
    },
    // 删除
    del: function (data) {
      var defaultCfg = {
        type: "get", // 默认请求类型
      };
      this.sa.ajax(
        "/v1/profitshare_account/delete/" + data.id,
        function () {
          //刷新数据
          this.f5();
        }.bind(this),
        defaultCfg
      );
    },
    //修改账号状态
    setStatus: function (data) {
      this.sa.confirm(
        "是否要修改账号状态",
        function () {
          var params = {
            status: data.status == 1 ? 1 : 0, //是否是请求体请求
          };
          var defaultCfg = {
            type: "get", // 默认请求类型
          };
          this.sa.ajax(
            "/v1/profitshare_account/updateStatus/" + data.id,
            params,
            function () {
              this.sa.ok("修改成功");
              this.f5();
            }.bind(this),
            defaultCfg
          );
        }.bind(this)
      );
    },
  },
  created: function () {
    this.f5();
  },
};
</script>

<style>
</style>
